import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import styles from "./home-css-module.module.scss"
//import logo from "../assets/solvLogo.svg"
import B2BVideo from "../assets/videos/B2BMarketplaceVideo.mp4"
import B2BVideoThumb from "../assets/Videos Thumbnails/B2B market place.png"
import HomeServices from "../assets/videos/HomeServices.mp4"
import HomeServicesThumb from "../assets/Videos Thumbnails/BusinessFinance video.png"
// import eCommerceMarketplace from "../../static/eCommerceMarketplace.png"
// import homeB2B from "../../static/homeB2B.png"
import getOnGPlay from "../../static/getOnGPlay.png"
// import numberLadyMob from "../../static/numberLadyMob.png"
import homeLadyMob from "../../static/homeLadyMob.webp"
import group77681 from "../../static/Group77681.webp"
// import serviceMobile from "../../static/serviceMobile.png"
import {
  Button,
  Layout,
  WhySolv,
  SolvInNews,
  BannerSlickWrapper,
  SEO,
  Testimonial,
} from "../components"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"

export default home => {
  const width =
    typeof window !== "undefined"
      ? window?.innerWidth ||
        document?.documentElement.clientWidth ||
        document?.body.clientWidth
      : 501
  console.log("width", width)
  const isWeb = width > 500
  const [ setWhySolvToggle] = useState(isWeb)

  function handleClickGetStarted() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.solv.commerce&referrer=utm_source%3Dwebsite%26utm_medium%3DhomePageCtaButton%26utm_term%3Dinstall"
    )
  }
  function handleClick() {
    navigate("/ecommerce")
  }
  function showWhySolv() {
    setWhySolvToggle(true)
  }

  function playPause(id, state) {
    let vid = document.getElementById(id)

    if (state) {
      vid.muted = true
      vid.play()
    } else {
      vid.pause()
    }
  }

  return (
    <>
      <Layout>
        <SEO
          title="Solv B2B platform| E-Commerce Marketplace| Credit for MSME"
          description="Solv is a B2B e-commerce marketplace for SMEs. It facilitates commerce while easing access to finance & business services through a seamless digital experience."
        />
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="itid1t3hwft4zi7yi49b4t255b5sgs"
          />
        </Helmet>
        <section className={styles.container}>
          <div className={styles.about}>
            {/* <img src={abtlogo} alt="logo" /> */}
            <div className={styles.imageContainer}>
              <BannerSlickWrapper page="homepage" />
            </div>
            <div className={styles.growthPlatform}>
              <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
                A growth platform for small business
                <div className={styles.sub}>
                  Commerce &#8226; Finance &#8226; Services
                </div>
              </div>
              <div className={styles.description}>
                Solv is a B2B E-Commerce marketplace for SMEs. The Solv platform
                facilitates commerce in a trusted environment, while easing
                access to finance and business services through one seamless
                digital experience.
              </div>
              <Button
                label="Get Started &nbsp; &#8594;"
                handleClick={handleClickGetStarted}
              />
              <Button
                type="secondary"
                label="Know More"
                handleClick={handleClick}
              />
            </div>
          </div>
          <section className={styles.marketPlaceSection}>
            <div className={styles.marketplaceBg}>
              <div className={styles.businessSize}>
                All that your business needs to grow - in one app
              </div>
              <div className={styles.marketPlaceContainer}>
                <div className={styles.marketPlace}>
                  <InView
                    as="div"
                    threshold="0.5"
                    onChange={(inView, entry) => playPause("B2BVideo", inView)}
                  >
                    <div>
                      <video
                        id="B2BVideo"
                        onClick={() => playPause("B2BVideo")}
                        loop
                        autoPlay
                        muted
                        playsInline
                        poster={B2BVideoThumb}
                      >
                        <source src={B2BVideo} type="video/mp4" />
                        {/* <source src={BusinessServicesVideo} type="video/quicktime"/> */}
                      </video>
                      {/* <video B2BVideo></video> */}
                      {/* <img className={styles.b2bweb} src={eCommerceMarketplace} alt="logo" />
                    <img className={styles.b2bmob} src={homeB2B} alt="logo" />                   */}
                    </div>
                  </InView>
                </div>
                <div className={styles.content}>
                  <div className={styles.homeCard}>
                    <div
                      className={[styles.heading, "heading2"].join(" ")}
                      onClick={handleClick}
                    >
                      B2B E-commerce marketplace
                    </div>
                    <div className={styles.description}>
                      <div>
                        Growing your business becomes easy once you start buying
                        or selling on the Solv B2B online marketplace. If you
                        are a seller, Solv helps you reach new and verified
                        customers. If you are a buyer, Solv helps you source
                        quality products at the best prices from verified and
                        pre-screened suppliers.
                        <br />
                        <br />
                        Value-added services such as on-demand credit, doorstep
                        pick-up and delivery, real time order tracking and
                        multiple payment options make doing business on Solv
                        quick and hassle-free.
                      </div>
                      <div>
                        <a onClick={handleClickGetStarted}>
                          <img src={getOnGPlay} alt="google play cta logo" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.numbersContainer}>
                  <div className={styles.numbersAlign}>
                    <div
                      className={[styles.numbers, styles.tempDashed].join(" ")}
                    >
                      <div className={styles.bold}>2300+</div>
                      <div>Sellers</div>
                    </div>
                    {/* <div className={[styles.numbers, styles.dashed].join(" ")}>
                    <div className={styles.bold}>10000+</div>
                    <div>Products</div>
                  </div> */}
                    <div className={styles.numbers}>
                      <div className={styles.bold}>200,000+</div>
                      <div> Buyers</div>
                    </div>
                  </div>
                  <div className={styles.numbersLady}>
                    {/* <img src={numberLadyMob} alt="logo" /> */}
                    <img src={homeLadyMob} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section>
          <div className={styles.servicesSection}>
            {/* <img className={styles.servicesSectionDesk} src={sevicesbg} alt="logo" /> */}
            <div className={styles.deskLady}>
              {/* <Img fluid={data.seriveLadyWeb.childImageSharp.fluid} alt="service image" /> */}
              <img src={group77681} alt="logo" />
            </div>
            <div className={styles.content}>
              <div className={styles.section1}>
                <div className={!isWeb ? styles.videoContainer : ""}>
                  <InView
                    as="div"
                    threshold="0.5"
                    onChange={(inView, entry) =>
                      playPause("FinancialServices", inView)
                    }
                  >
                    <video
                      id="FinancialServices"
                      onClick={() => playPause("FinancialServices")}
                      className={styles.servicesSectionMob}
                      loop
                      autoPlay
                      muted
                      playsInline
                      poster={HomeServicesThumb}
                    >
                      <source src={HomeServices} type="video/mp4" />
                    </video>
                  </InView>
                  {/* <img
                    className={styles.servicesSectionMob}
                    src={serviceMobile}
                    alt="logo"
                  /> */}
                </div>
                <div
                  className={[styles.homeCard, styles.financialCardColor].join(
                    " "
                  )}
                >
                  <div className={[styles.heading, "heading2"].join(" ")}>
                    Financial services
                  </div>
                  <div className={styles.financialBusiness}>
                    <div>
                      As a Solv customer, you can take advantage of timely
                      credit to grow your business. Solv offers a comprehensive
                      suite of financial products comprising business loans,
                      invoice-based financing (for orders placed on the Solv B2B
                      marketplace) and anchor led dealer finance. A robust and
                      secure origination platform along with a wide network of
                      banks and NBFC partners ensures faster processing and
                      approvals. The Solv Score also helps establish your
                      credibility among financial service providers.
                    </div>
                    {/* <div className={styles.hyperlink}>Read More</div> */}
                  </div>
                </div>
              </div>
              <div className={styles.videoContainer}>
                <InView
                  as="div"
                  threshold="0.5"
                  onChange={(inView, entry) =>
                    playPause("HomeServices", inView)
                  }
                >
                  <video
                    id="HomeServices"
                    onClick={() => playPause("HomeServices")}
                    loop
                    autoPlay
                    muted
                    playsInline
                  >
                    <source src={HomeServices} type="video/mp4" />
                  </video>
                </InView>
                {/* <img
                  className={styles.servicesSectionDesk2}
                  src={serviceMobile}
                  alt="logo"
                /> */}
              </div>
              <div className={styles.section2}>
                <div>
                  {/* <video className={styles.servicesSectionMob2} id='HomeServicesMob' onClick={() => playPause('HomeServicesMob')} autoPlay loop>
                    <source src={HomeServices} type="video/mp4"/>
                    <source src={BusinessServicesVideo} type="video/quicktime"/>
                  </video> */}
                  {/* <img
                    className={styles.servicesSectionMob2}
                    src={serviceMobile}
                    alt="logo"
                  /> */}
                </div>
                <div
                  className={[styles.homeCard, styles.businessCardColor].join(
                    " "
                  )}
                >
                  <div className={[styles.heading, "heading2"].join(" ")}>
                    Business services
                  </div>
                  <div className={styles.financialBusiness}>
                    <div>
                      Finding time to focus on growing the business is
                      challenging while running day-to-day operations. Solv
                      offers you access to reliable and affordable business
                      services. From logistics to warehousing; Solv’s business
                      services offering are designed to take care of your most
                      critical business needs on time and within budget.
                    </div>
                    {/* <div className={styles.hyperlink}>Read More</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.whyChooseSolvSection}>
            <div className={[styles.heading, "heading1Medium"].join(" ")}>
              Why choose Solv?
            </div>
            <div className={styles.reasonsContainer}>
              <div className={[styles.reason, styles.one].join(" ")}>
                <WhySolv
                  icon="home1"
                  number="1"
                  description="Pure marketplace model connecting buyers & sellers for an open and transparent business environment"
                />
              </div>
              <div className={[styles.reason, styles.four].join(" ")}>
                <WhySolv
                  icon="home2"
                  number="4"
                  description="Superior customer experience across all touchpoints including delivery, payments & support"
                />
              </div>
              <div className={[styles.reason, styles.two].join(" ")}>
                <WhySolv
                  icon="home3"
                  number="2"
                  description="One-stop shop offering value added services inclusive of financial and business services"
                />
              </div>
              <div className={[styles.reason, styles.five].join(" ")}>
                <WhySolv
                  icon="home4"
                  number="5"
                  description="AI and ML enabled platform for data-driven decisioning and end-to-end transaction enablement"
                />
              </div>
              <div className={[styles.reason, styles.three].join(" ")}>
                <WhySolv
                  icon="home5"
                  number="3"
                  description="Solv score creates a trust-based ecosystem & serves as a reliable, credit score for SMEs"
                />
              </div>
              <div className={[styles.reason, styles.six].join(" ")}>
                <WhySolv
                  icon="home6"
                  number="6"
                  description="Standard Chartered assurance ensures fair business practices and tighter controls on data security and privacy"
                />
              </div>
            </div>
            {/* {!whySolvToggle && <div className={styles.viewAllContainer}>
              <Button type="outline" label="View All" handleClick={showWhySolv}></Button>
            </div>} */}
          </div>
        </section>
        <section>
          <div className={styles.solvInNewsBg}>
            <SolvInNews></SolvInNews>
          </div>
        </section>
        <section>
          {/* <VideoCard /> */}
          <div>
            <Testimonial></Testimonial>
          </div>
        </section>
      </Layout>
    </>
  )
}
